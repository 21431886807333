import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Layout } from "./Layout";
import { theme } from "./theme";
import { StatusPage } from "./pages/StatusPage";
import Complete from "./pages/complete";
import EnergyReportPage from "./pages/EnergyReportPage";
import { LoginPage } from "./pages/LoginPage";
import { useAuth0 } from "@auth0/auth0-react";

export const App: React.FC = () => {
  const isAuthenticated = useAuth0().isAuthenticated;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "",
          element: isAuthenticated ? (
            <Navigate to="/status" replace />
          ) : (
            <Navigate to="/login" replace />
          ),
        },
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/status",
          element: isAuthenticated ? <StatusPage /> : <LoginPage />,
        },
        {
          path: "/complete",
          element: <Complete />,
        },
        {
          path: "/energy_report/:id",
          element: <EnergyReportPage />,
        },
      ],
    },
  ]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={theme}>
        <RouterProvider router={router} fallbackElement={<>Loading...</>} />
      </MantineProvider>
    </QueryClientProvider>
  );
};
