import { Code, Container, MantineProvider, Paper, Title } from "@mantine/core";
import { SignInButton } from "../components/Login/SignInButton";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

export const LoginPage = () => {
  const isAuthenticated = useAuth0().isAuthenticated;
  if (isAuthenticated) return <Navigate to="/" replace />;

  return (
    <Container size={600} my={60}>
      <Paper withBorder shadow="md" p={90} mt={90} radius="md">
        <MantineProvider
          theme={{
            headings: { fontFamily: "Greycliff CF, sans-serif" },
          }}
        >
          <Title order={3}>Verified user required:</Title>
          <br />
          <SignInButton />
          <br />
          <Code>Admin - Reduce AS</Code>
        </MantineProvider>
      </Paper>
    </Container>
  );
};
