import { Button } from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";

export const SignInButton = () => {
  const { loginWithPopup } = useAuth0();

  return (
    <Button fullWidth onClick={() => loginWithPopup()}>
      Sign in
    </Button>
  );
};
