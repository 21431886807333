import React, { useEffect, useRef } from "react";
import {
  select,
  scaleLinear,
  scaleTime,
  axisLeft,
  axisRight,
  line,
  extent,
  axisBottom,
  timeMonth,
  timeFormat,
} from "d3";
import { ReportData } from "../../api/reports";
import SectionTitle from "./SectionTitle";
import Paragraph from "antd/es/typography/Paragraph";
import { Col, Row } from "antd";
import Title from "antd/es/typography/Title";

function SectionTempVsElectricity({ reportData }: { reportData: ReportData }) {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    // Constants for the chart dimensions
    var margin = { top: 20, right: 40, bottom: 30, left: 40 },
      width = 900 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;

    // Prepare the data
    const dailyElectricityUsage = reportData.daily_electricity_usage_kwh;
    const monthAverageTemperature = reportData.month_average_temperature;

    // Determine the starting date for the x-axis (today - 365 days)
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 365);

    // Calculate monthly averages from daily usage data
    const monthlyAverages: number[] = [];
    for (let i = 0; i < 12; i++) {
      const startIndex = i * 30;
      const endIndex = startIndex + 30;
      const monthlyAverage =
        dailyElectricityUsage
          .slice(startIndex, endIndex)
          .reduce((acc, val) => acc + val, 0) / 30;
      monthlyAverages.push(monthlyAverage);
    }

    // Create the SVG element
    const svg = select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Create scales for the x and y axes
    const xScale = scaleTime()
      .domain([startDate, new Date()])
      .range([margin.left, width - margin.right]);

    const yScaleTemperature = scaleLinear()
      .domain(extent(monthAverageTemperature) as [number, number])
      .range([margin.top, height - margin.bottom]);

    // const yScaleElectricity = scaleLinear()
    //     .domain(extent(monthlyAverages) as [number, number])
    //     .range([height - margin.bottom, margin.top]);

    const maxMonthlyUsage = Math.max(...monthlyAverages);

    // Create a new yScaleElectricity with the domain starting from 0
    const yScaleElectricity = scaleLinear()
      .domain([0, maxMonthlyUsage])
      .range([height - margin.bottom, margin.top]);

    //     const yScaleElectricity = scaleLinear()
    // .domain([0, extent(monthlyAverages)[1]]) // Set the domain to start from 0
    // .range([height - margin.bottom, margin.top]);

    // Create axes
    const xAxis = axisBottom<Date>(xScale)
      .ticks(timeMonth.every(1))
      .tickFormat((date) => timeFormat("%b")(date));

    const yAxisTemperature = axisLeft(yScaleTemperature);
    const yAxisElectricity = axisRight(yScaleElectricity);

    // Create temperature line generator
    const temperatureLine = line<number>()
      .x((_, i) =>
        xScale(new Date(startDate.getTime() + i * 30 * 24 * 60 * 60 * 1000)),
      )
      .y((d) => yScaleTemperature(d));

    // Create electricity usage line generator
    const electricityLine = line<number>()
      .defined((d) => d !== 0) // Skip points where electricity usage is 0
      .x((_, i) =>
        xScale(new Date(startDate.getTime() + i * 30 * 24 * 60 * 60 * 1000)),
      ) // Using monthly intervals
      .y((d) => yScaleElectricity(d));

    // Draw the temperature line
    svg
      .append("path")
      .datum(monthAverageTemperature)
      .attr("fill", "none")
      .attr("stroke", "slateblue")
      .attr("stroke-width", 2)
      .attr("d", temperatureLine);

    // Draw the electricity usage line
    svg
      .append("path")
      .datum(monthlyAverages)
      .attr("fill", "none")
      .attr("stroke", "orange")
      .attr("stroke-width", 2)
      .attr("d", electricityLine);

    // Append axes to the SVG
    svg
      .append("g")
      .attr("transform", `translate(0, ${height - margin.bottom})`)
      .call(xAxis);

    svg
      .append("g")
      .attr("transform", `translate(${margin.left}, 0)`)
      .call(yAxisTemperature);

    svg
      .append("g")
      .attr("transform", `translate(${width - margin.right}, 0)`)
      .call(yAxisElectricity);

    // Define label data with colors and line styles
    const labels = [
      { text: "Temperature (°C)", color: "slateblue", style: "3" },
      { text: "Electricity Usage (kWh)", color: "orange", style: "3" },
    ];

    // Create and append a legend container
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr(
        "transform",
        `translate(${width - margin.right - 200}, ${margin.top})`,
      ); // Adjust the horizontal offset here

    // Create and append legend items
    const legendItems = legend
      .selectAll(".legend-item")
      .data(labels)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (_, i) => `translate(0, ${i * 20})`);

    // Create colored lines in the legend
    legendItems
      .append("line")
      .attr("x1", 0)
      .attr("x2", 20)
      .attr("y1", 10)
      .attr("y2", 10)
      .attr("stroke", (d) => d.color)
      .attr("stroke-width", (d) => d.style);

    // Create text labels in the legend
    legendItems
      .append("text")
      .attr("x", 30)
      .attr("y", 10)
      .attr("font-size", "12px")
      .text((d) => d.text)
      .attr("alignment-baseline", "middle")
      .attr("fill", "black");

    svg
      .append("g")
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .attr("font-size", "12px")
      .style("fill", "black")
      .text("Gjennomsnittstemperatur per måned på din adresse (°C)");

    svg
      .append("g")
      .attr("transform", "translate(" + width + ",0)")
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -10)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .attr("font-size", "12px")
      .style("fill", "black")
      .text("Forbruk elektrisitet på din adresse per måned (kWh)");
  }, [reportData]);

  return (
    <div>
      <section aria-describedby="overview">
        <SectionTitle
          title="Når strømforbruk og utetemperatur følger hverandre tett, har du mye å spare på ENØK"
          id="overview"
        />
        <Paragraph>
          Lavere utetemperatur på din adresse fører til høyere strømforbruk.
          Dette er vanlig. Men når strømforbrukslinjen henger tett sammen med
          temperaturlinjen, tyder dette på at husets strømforbruk i stor grad
          påvirkes av utetemperatur. Da har du mye å spare på ENØK.
        </Paragraph>
        <Row>
          <Col span={24}>
            <svg
              ref={svgRef}
              style={{ width: "100%", height: "auto" }}
              viewBox="0 0 820 450"
              preserveAspectRatio="xMidYMid meet"
            ></svg>
            <br></br>
            <br></br>
            <br></br>
          </Col>
        </Row>
      </section>

      <section aria-describedby="tips">
        <SectionTitle title="Tips til energieffektivisering" id="tips" />
        <Paragraph>
          Det er mange ting du kan gjøre for å spare strøm, og dermed penger.
          Her er våre beste tips til hva du kan gjøre for å energieffektivisere
          boligen din.
        </Paragraph>

        <Paragraph>
          Etterisolering gir en umiddelbar og langvarig effekt på
          oppvarmingskostnadene. De mest lønnsomme tiltakene i denne kategorien
          er:
        </Paragraph>

        <Title level={4}>Etterisolering av bjelkelag mot kalde loft</Title>
        <Paragraph>
          Uisolerte eller dårlig isolerte bjelkelag mot kalde loft gjør at mye
          av den varme lufta i øverste etasje forsvinner opp på loftet. Det er i
          stor grad bortkastet energi. Øker du isolasjonstykkelsen i bjelkelaget
          mot kalde loft fra 10 til 30 cm i en typisk bolig på 100 m2, kan du
          spare ca. 3000 kWh årlig.{" "}
        </Paragraph>

        <Title level={4}>Etterisolering av yttervegger</Title>
        <Paragraph>
          Mye av varmetapet i en bolig foregår gjennom ytterveggene, og det er
          mye å hente på å øke isolasjonstykkelsen. Øker du fra 10 til 25 cm
          isolasjon og monterer ny vindsperre vil du kunne oppnå en besparelse
          på 39 kWh i oppvarmingskostnad per kvadratmeter isolert areal.
        </Paragraph>

        <Title level={4}>Bytte ut gamle vinduer</Title>
        <Paragraph>
          Dersom du har vinduer fra 1980-tallet eller tidligere, har de stort
          varmetap. I en bolig med totalt 15 m2 vindusareal, der vinduene har en
          U-verdi på 2,5, vil du kunne spare ca. 2300 kWh i året ved å bytte til
          trelagsvinduer med U-verdi på 0,8.
        </Paragraph>

        <Title level={4}>Etterisolering av bjelkelag mot kald kjeller</Title>
        <Paragraph>
          En økning fra 10 til 20 centimeter i bjelkelag vil kunne gi en
          besparelse 15 kWh per kvadratmeter isolert areal. I tillegg blir ikke
          gulvene over kjeller og kryperom så kalde.
        </Paragraph>

        <Title level={4}>Energieffektive produkter og løsninger</Title>
        <Paragraph>
          Er det ikke tid eller penger til større etterisoleringsprosjekter kan
          energieffektive produkter og løsninger være en god vei å gå.
        </Paragraph>

        <Title level={4}>Smart strømstyring</Title>
        <Paragraph>
          Smart strømstyring innebærer å styre såkalt flyttbart forbruk til
          tider på døgnet der strømprisene er lavest. Dette gjelder typisk
          forbruk til lading av elbil, oppvarming av varmtvann og gulvvarme. Det
          er både mulig å installere systemer som styrer flere strømforbrukere
          eller å investere i enkeltprodukter som styres etter strømprisen,
          typisk varmtvannsberedere og ladestasjoner for elbil.{" "}
        </Paragraph>

        <Title level={4}>Energieffektiv oppvarming</Title>
        <Paragraph>
          Har du eldre eller lite energieffektive oppvarmingsprodukter kan de
          byttes ut med nye og mer effektive. Eksempelvis vil du kunne halvere
          kostnaden ved romoppvarming ved å gå fra elektrisk oppvarming til
          luft/luft-varmepumpe. Ønsker du ikke å bytte ut eksisterende
          oppvarmingsprodukter, er det lurt å stille dem inn på dag- og
          nattsenking slik at temperaturen er lavere på dag- og nattestid. Dette
          vil kunne gi en reduksjon i strømforbruk på mellom 15 og 25 prosent.
        </Paragraph>

        <Title level={4}>Solcelleanlegg</Title>
        <Paragraph>
          Har du takarealer med god solinnstråling kan et solcelleanlegg være en
          god investering da det dekker en god del av boligens strømforbruk. Med
          en plusskundeavtale kan du også tjene noen kroner på å selge
          overskuddsenergi tilbake på nettet.
        </Paragraph>
      </section>
    </div>
  );
}

export default SectionTempVsElectricity;
