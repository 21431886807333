import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Form, Table, Typography, theme } from "antd";
import { Button, Loader, useMantineTheme } from "@mantine/core";
import { EditableCell } from "./EditableCell";
import Error from "../Error";
import {
  defaultMeterpoint,
  getMeterpoints,
  addOrUpdateMeterpoint,
  Meterpoint,
  Meterpoints,
} from "../../api/meterpoints";
import { useAuth0 } from "@auth0/auth0-react";

export type Record = Meterpoint & { statusName: string };

const { useToken } = theme;

export const StatusList: React.FC = () => {
  const theme = useMantineTheme();
  const { token } = useToken();
  const queryClient = useQueryClient();
  const { getAccessTokenSilently } = useAuth0();

  const accessToken = getAccessTokenSilently();
  const userAuthenticated = useAuth0().isAuthenticated;

  const {
    isLoading,
    isError,
    data: meterpoints,
  } = useQuery<Meterpoint[]>({
    queryKey: ["meterpoints"],
    queryFn: async () => {
      const accessToken = getAccessTokenSilently();
      return userAuthenticated ? getMeterpoints(await accessToken) : [];
    },
  });

  const addOrUpdateMeterpointData = async (
    newMeterpoint: Meterpoint,
    instance: Promise<string>,
  ) => {
    const previousMeterpoints = queryClient.getQueryData<Meterpoints>([
      "meterpoints",
    ]);

    // Only proceed if the initial query has completed and there is existing data
    if (!isLoading && !isError && previousMeterpoints) {
      await queryClient.cancelQueries(["meterpoints"]);

      const index = previousMeterpoints.findIndex(
        (meterpoint) => meterpoint.meterId === newMeterpoint.meterId,
      );

      if (index > -1) {
        queryClient.setQueryData<Meterpoints>(["meterpoints"], (old) =>
          old
            ? old.map((meterpoint, i) =>
                index === i ? newMeterpoint : meterpoint,
              )
            : [],
        );
      } else {
        queryClient.setQueryData<Meterpoints>(["meterpoints"], (old) => [
          newMeterpoint,
          ...(old ?? []),
        ]);
      }

      if (userAuthenticated) {
        try {
          const result = await addOrUpdateMeterpoint(
            newMeterpoint,
            await accessToken,
          );

          return result;
        } catch (error) {
          console.error("Error adding or updating meterpoint:", error);
          if (previousMeterpoints) {
            queryClient.setQueryData<Meterpoints>(
              ["meterpoints"],
              previousMeterpoints,
            );
          }
          throw error;
        } finally {
          queryClient.invalidateQueries(["meterpoints"]);
        }
      }
    }
  };

  const [form] = Form.useForm();
  const [editingMeterId, setEditingMeterId] = useState<string | null>(null);

  const isEditing = (record: Meterpoint) => record.meterId === editingMeterId;
  const isAdding = editingMeterId === "";

  const edit = (record: Meterpoint) => {
    form.setFieldsValue({ ...defaultMeterpoint, ...record });
    setEditingMeterId(record.meterId);
  };

  const cancel = () => {
    setEditingMeterId(null);
  };

  const save = async (meterId: string) => {
    try {
      const meterInfo = (await form.validateFields()) as Meterpoint;
      // had to do this weird hack to get id working
      meterInfo["id"] = form.getFieldValue("id");

      setEditingMeterId(null);
      if (meterpoints) {
        const index = meterpoints.findIndex(
          (meterpoint) => meterpoint.meterId === meterId,
        );

        let meterpoint;
        if (index > -1) {
          meterpoint = {
            ...meterpoints[index],
            id: meterInfo.id,
            meterId: meterInfo.meterId,
            meterNumber: meterInfo.meterNumber,
            lat: meterInfo.lat,
            lng: meterInfo.lng,
            firstName: meterInfo.firstName,
            lastName: meterInfo.lastName,
            email: meterInfo.email,
            address: meterInfo.address,
            zipCode: meterInfo.zipCode,
            city: meterInfo.city,
            buildingType: meterInfo.buildingType,
            timeStamp: meterInfo.timeStamp,
            squareMeters: meterInfo.squareMeters,
            enokNotes: meterInfo.enokNotes,
            otherInfo: meterInfo.otherInfo,
          };
        } else {
          meterpoint = {
            ...defaultMeterpoint,
            ...meterInfo,
          };
        }
        addOrUpdateMeterpointData(meterpoint, accessToken);
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const add = () => {
    form.setFieldsValue({ ...defaultMeterpoint });
    setEditingMeterId("");
  };

  const statusMap: { [key: number]: string } = {
    0: "Status 0: Unknown Status",
    1: "Status 1: Registered",
    2: "Status 2: Waiting for meter data",
    3: "Status 3: Pending",
    4: "Status 4: Energy report ready",
    5: "Status 5: Email Sent",
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      editable: false,
      width: 200,
      rules: [
        {
          required: true,
          message: "ID is required.",
        },
      ],
    },
    {
      title: "Edit",
      dataIndex: "operation",
      render: (_: any, record: Meterpoint) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.meterId)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Typography.Link onClick={cancel}>Cancel</Typography.Link>
          </span>
        ) : (
          <Typography.Link
            disabled={editingMeterId !== null}
            onClick={() => edit(record)}
            style={{ paddingLeft: 20, alignContent: "center" }}
          >
            Edit
          </Typography.Link>
        );
      },
    },
    {
      title: "Meter ID",
      dataIndex: "meterId",
      editable: true,
      width: 120,
      rules: [
        {
          required: true,
          message: "Meter ID is required.",
        },
      ],
      render: (text: string, record: Record) => (
        <Link to={`/energy_report/${record.meterId}`}>{text}</Link>
      ),
    },
    {
      title: "Meter Number",
      dataIndex: "meterNumber",
      editable: true,
      width: 120,
    },
    {
      title: "Registered At",
      dataIndex: "timeStamp",
      editable: true,
      render: (text: string) => {
        const date = new Date(text);
        return date.toLocaleDateString();
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text: number) => statusMap[text] || text, // Render status text based on the status code
    },
    {
      title: "Latitude",
      dataIndex: "lattitude",
      editable: true,
      width: 120,
      rules: [
        {
          type: "number",
          transform: (value: string) => Number(value),
          message: "Not valid latitude",
        },
        {
          required: true,
          message: "Latitude is required.",
        },
      ],
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      editable: true,
      width: 120,
      rules: [
        {
          type: "number",
          transform: (value: string) => Number(value),
          message: "Not valid longitude",
        },
        {
          required: true,
          message: "Longitude is required.",
        },
      ],
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      editable: true,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      editable: true,
    },
    {
      title: "Address",
      dataIndex: "streetName",
      editable: true,
    },
    {
      title: "Zip Code",
      dataIndex: "zipCode",
      width: 120,
      editable: true,
    },
    {
      title: "City",
      dataIndex: "cityName",
      editable: true,
    },
    {
      title: "Building Type",
      dataIndex: "buildingType",
      editable: true,
    },
    {
      title: "Square Meters",
      dataIndex: "squareMeters",
      editable: true,
    },
    {
      title: "Enok Notes",
      dataIndex: "enokNotes",
      editable: true,
    },
    {
      title: "Other Info",
      dataIndex: "otherInfo",
      editable: true,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;
    return {
      ...col,
      onCell: (record: Meterpoint) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        rules: col.rules,
        render: col.render,
        editing: isEditing(record),
      }),
    };
  });

  const mergedDataSource = isAdding
    ? [
        {
          ...defaultMeterpoint,
        },
        ...(meterpoints ?? []),
      ]
    : meterpoints ?? [];

  if (isLoading) return <Loader color={theme.colors.teal[4]} />;
  if (isError) return <Error />;

  const generateUniqueMeterId = () => {
    // Logic to generate a unique key Id
    return `${Date.now()}-${Math.random().toString(36).substring(7)}`;
  };

  return (
    <>
      <Button
        variant="filled"
        disabled={isAdding}
        onClick={add}
        style={{ marginBottom: token.marginMD }}
      >
        Add new entry
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={mergedDataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          rowKey={(record) =>
            `${record.meterId}-${
              record.meterNumber !== ""
                ? record.meterNumber
                : generateUniqueMeterId()
            }`
          }
          pagination={false}
          sticky
          scroll={{ x: 2400, y: 500 }}
        ></Table>
      </Form>
    </>
  );
};
