import { Col, Row, theme } from "antd";
import ReportTitle from "./ReportTitle";
// import SectionHeating from "./SectionHeating";
// import SectionAlwaysOn from "./SectionAlwaysOn";
// import SectionBehavior from "./SectionBehavior";
// import SectionOverview from "./SectionOverview";
// import SectionPowerConsumption from "./SectionPowerConsumption";
import { ReportData } from "../../api/reports";
import SectionTempVsElectricity from "./SectionTempVsElectricity";

const { useToken } = theme;

function EnergyReport({ reportData }: { reportData: ReportData }) {
  const { token } = useToken();

  return (
    <>
      <Row justify="center">
        <Col
          span={24}
          md={20}
          lg={18}
          xl={16}
          xxl={12}
          style={{
            paddingInline: token.paddingContentHorizontal,
          }}
        >
          <article>
            <ReportTitle address={reportData.address} />

            {/*<SectionHeating reportData={reportData} />
            <SectionAlwaysOn reportData={reportData} />
            <SectionBehavior reportData={reportData} />
            <SectionOverview reportData={reportData} />
            <SectionPowerConsumption reportData={reportData} /> */}
            <SectionTempVsElectricity reportData={reportData} />
          </article>
        </Col>
      </Row>
    </>
  );
}

export default EnergyReport;
