import { AppShell, Button, CloseButton, Group, Input } from "@mantine/core";
import { Outlet, useNavigate } from "react-router-dom";
import { REDUCE_ENERGY_URL } from "./utils/constants";
import React, { useState } from "react";
import { SignOutButton } from "./components/Login/SignOutButton";
import { useAuth0 } from "@auth0/auth0-react";

export const Layout: React.FC = () => {
  const navigate = useNavigate();
  const isAuthenticated = useAuth0().isAuthenticated;

  const [energyReportId, setEnergyReportId] = useState("");

  const handleEnergyReportClick = (event: React.FormEvent) => {
    event.preventDefault();

    if (energyReportId !== "") {
      navigate(`/energy_report/${energyReportId}`);
    }
  };

  return (
    <AppShell header={{ height: 75 }} padding="md">
      <AppShell.Header style={{ paddingTop: 15 }}>
        <Group
          style={{ marginLeft: 15, display: "flex", flexDirection: "row" }}
        >
          <a href={REDUCE_ENERGY_URL} style={{ textDecoration: "none" }}>
            <img
              src="/reduce1.png"
              alt="Reduce Energy"
              style={{ height: 40, marginLeft: 10 }}
            />
          </a>
          {isAuthenticated && (
            <>
              <Group style={{ paddingLeft: 30 }}>
                <Button onClick={handleEnergyReportClick}>Create report</Button>
                <Input
                  placeholder="from report ID ..."
                  value={energyReportId}
                  onChange={(event) =>
                    setEnergyReportId(event.currentTarget.value)
                  }
                  rightSectionPointerEvents="all"
                  rightSection={
                    <CloseButton
                      aria-label="Report ID"
                      onClick={() => setEnergyReportId("")}
                      style={{ display: energyReportId ? undefined : "none" }}
                    />
                  }
                />
              </Group>
              <Group style={{ marginLeft: "auto", marginRight: 20 }}>
                <SignOutButton />
              </Group>
            </>
          )}
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
