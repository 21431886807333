/**
 * External
 */
export const REDUCE_ENERGY_URL = "https://reduceenergy.no";
export const REDUCE_ENERGY_API_URL = "https://api.reduceenergy.no";
export const REDUCE_API = "https://reduce-api-staging.azurewebsites.net";
export const REDUCE_REDIRECT = "https://app.reduceenergy.no";
/**
 * Navigation
 */
export const METERPOINT_PATH = "/meterpoint";
export const LOCALHOST_REDIRECT = "http://localhost:3000";
export const LOCAL_REDUCE_API = "https://localhost:7107";
