import { Alert } from "antd";

const Error = ({
  message = "Something went wrong. Please try again later.",
}: {
  message?: string;
}) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <Alert message="Error" description={message} type="error" showIcon />
  </div>
);

export default Error;
