import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./main.css";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  LOCALHOST_REDIRECT,
  REDUCE_ENERGY_API_URL,
  REDUCE_REDIRECT,
} from "./utils/constants";
import { authConfig } from "./authentication/authConfig";

const isDevelopment = process.env.NODE_ENV === "development";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      authorizationParams={{
        audience: REDUCE_ENERGY_API_URL,
        redirect_uri:
          isDevelopment !== null && isDevelopment
            ? LOCALHOST_REDIRECT
            : REDUCE_REDIRECT,
      }}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
);
