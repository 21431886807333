import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getReport } from "../api/reports";
import EnergyReport from "../components/EnergyReport";
import Error from "../components/Error";
import { Loader, useMantineTheme } from "@mantine/core";

export default function EnergyReportPage() {
  const theme = useMantineTheme();

  const { id } = useParams();
  const { isLoading, isError, data } = useQuery({
    queryKey: ["energyReport", id ?? "1"],
    queryFn: ({ queryKey }) => getReport(queryKey[1]),
  });

  if (isLoading) {
    return <Loader color={theme.colors.teal[4]} />;
  }
  if (isError) {
    return <Error />;
  }
  return <EnergyReport reportData={data} />;
}
