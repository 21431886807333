import axios from "axios";

export type ReportData = {
  address: "string";
  r_number_perc: number;
  is_smart_controlled: boolean;
  energy_breakdown_kwh: {
    base_usage: number;
    heating: number;
    ecar: number;
    other: number;
  };
  month_average_temperature: number[];
  daily_electricity_usage_kwh: number[];
  potential_daily_electricity_usage_kwh: number[];
};

export const getReport = (id: string) =>
  axios
    .get<ReportData>(
      `https://reducedatascienceapi.azurewebsites.net/report/${id}`,
    )
    .then((res) => res.data);
