import { Col, Row, Space, theme } from "antd";
import Step from "./Step";
import { useLocation } from "react-router-dom";
import Link from "antd/es/typography/Link";

const { useToken } = theme;

const stepsData = [
  {
    id: 1,
    image: "/images/page_1.png",
    description: (
      <span>
        Trinn 1: Gå inn på{" "}
        <a href="https://elhub.no" target="_blank" rel="noopener noreferrer">
          elhub.no
        </a>{" "}
        og velg Min Side
      </span>
    ),
  },
  {
    id: 2,
    image: "/images/page_2.png",
    description: "Trinn 2: Logg deretter inn",
  },
  {
    id: 3,
    image: "/images/page_3.png",
    description:
      "Trinn 3: Gå inn på oversikt målepunkter og finn ny forespørsel(menyen)",
  },
  {
    id: 4,
    image: "/images/page_4.png",
    description: "Trinn 4: Godta forespørsel fra Cegal.",
  },
];

function Complete() {
  const { token } = useToken();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const param = queryParams.get("bankid");

  if (param === "0") {
    return (
      <>
        <Row justify="center">
          <Col
            span={24}
            md={20}
            lg={18}
            xl={16}
            xxl={12}
            style={{
              paddingInline: token.paddingContentHorizontal,
            }}
          >
            <br></br>
            <br></br>
            <h1>Tusen takk for din bestilling!</h1>
            <h2>
              For at vi skal få tilgang til dine strømdata må du gå inn på
              elhub.no og følge stegene under.
            </h2>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <div className="step-list">
                {stepsData.map((step) => (
                  <Step key={step.id} step={step} />
                ))}
              </div>
            </Space>
            <div>
              <p>Etter det vil du motta en epost når rapporten er klar.</p>
              <p>Med ønsker om en fortsatt god dag</p>
              <strong>
                <p>Dr. Simon Funke</p>
                <p>Leder Reduce Data Science Team</p>
              </strong>
            </div>
          </Col>
        </Row>
        <div style={{ margin: "30px", textAlign: "center" }}>
          <Link href="mailto:post@reduceenergy.no">Send oss ​​en e-post</Link>
          {" | "}
          <Link
            href="https://reduceenergy.no/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Besøk vår hjemmeside
          </Link>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Row justify="center">
          <Col
            span={24}
            md={20}
            lg={18}
            xl={16}
            xxl={12}
            style={{
              paddingInline: token.paddingContentHorizontal,
            }}
          >
            <br></br>
            <br></br>
            <div>
              <h1>Tusen takk for din bestilling!</h1>
              <p>
                Tusen takk for din bestilling og tålmodighet. Vi henter nå ut
                strømdata, værdata og annen relevant informasjon for å lage en
                tidlig fase analyse av strømforbruket i din bolig. Du vil motta
                rapporten per e-post innen 24 timer
              </p>
              <p>Med ønsker om en fortsatt god dag</p>
              <strong>
                <p>Dr. Simon Funke</p>
                <p>Leder Reduce Data Science Team</p>
              </strong>
            </div>
          </Col>
        </Row>

        <div style={{ margin: "30px", textAlign: "center" }}>
          <Link href="mailto:post@reduceenergy.no">Send oss ​​en e-post</Link>
          {" | "}
          <Link
            href="https://reduceenergy.no/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Besøk vår hjemmeside
          </Link>
        </div>
      </>
    );
  }
}

export default Complete;
