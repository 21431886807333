import { Form, Input } from "antd";
import { Record } from "./StatusList";
import { RuleObject } from "antd/es/form";
import styles from "./EditableCell.module.css";
import { Link } from "react-router-dom";
import React from "react";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  record: Record;
  rules?: RuleObject[];
  to?: (record: Record) => string;
  children: React.ReactNode;
}

export const EditableCell = React.memo(
  ({
    editing,
    dataIndex,
    title,
    record,
    rules = [],
    to,
    children,
  }: EditableCellProps) => {
    if (editing)
      return (
        <td>
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={rules}
            className={styles.editableCell}
          >
            <Input placeholder={title} />
          </Form.Item>
        </td>
      );
    if (to)
      return (
        <td>
          {record.statusName === "Ready" ? (
            <Link to={to(record)}>{children}</Link>
          ) : (
            children
          )}
        </td>
      );
    else return <td>{children}</td>;
  },
);
