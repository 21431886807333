import axios from "axios";
import {
  LOCAL_REDUCE_API,
  METERPOINT_PATH,
  REDUCE_API,
} from "../utils/constants";
export type Meterpoints = Meterpoint[];

export type Meterpoint = {
  id: any;
  meterId: string;
  meterNumber: string;
  lat: number;
  lng: number;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  zipCode: string;
  city: string;
  buildingType: string;
  squareMeters: string;
  enokNotes: string;
  otherInfo: string;
  status: number;
  statusName: string;
  timeStamp: string;
};

export const defaultMeterpoint = {
  id: "",
  meterId: "",
  meterNumber: "",
  lat: 0,
  lng: 0,
  firstName: "",
  lastName: "",
  email: "",
  address: "",
  zipCode: "",
  city: "",
  buildingType: "",
  squareMeters: "",
  enokNotes: "",
  otherInfo: "",
  status: 0,
  statusName: "",
  timeStamp: "",
};

export const getMeterpoints = async (token: string) => {
  try {
    const isDevelopment = process.env.NODE_ENV === "development";

    const res = await axios.get<Meterpoint[]>(
      isDevelopment !== null && isDevelopment
        ? LOCAL_REDUCE_API + METERPOINT_PATH
        : REDUCE_API + METERPOINT_PATH,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data;
  } catch (error) {
    console.error("Error getting meterpoints:", error);
    throw error;
  }
};

export const addOrUpdateMeterpoint = async (
  meterpoint: Meterpoint,
  token: string,
) => {
  try {
    const response = await axios.post(
      REDUCE_API + METERPOINT_PATH,
      meterpoint,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.error("Error adding/updating meterpoint:", error);
    throw error;
  }
};
