import { theme, Typography } from "antd";

const { Title, Paragraph } = Typography;
const { useToken } = theme;

function ReportTitle({ address }: { address: string }) {
  const { token } = useToken();

  return (
    <>
      <hgroup>
        <Title>Energirapport</Title>
        <Paragraph
          style={{
            fontSize: token.fontSizeHeading3,
            lineHeight: token.lineHeightHeading3,
          }}
        >
          for {address}
        </Paragraph>
      </hgroup>
    </>
  );
}

export default ReportTitle;
