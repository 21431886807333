import { theme, Typography } from "antd";

const { Title } = Typography;
const { useToken } = theme;

function SectionTitle({ title, id }: { title: string; id: string }) {
  const { token } = useToken();

  return (
    <Title
      level={2}
      id={id}
      style={{
        marginBlockStart: token.marginXXL,
        marginBlockEnd: 0,
      }}
    >
      {title}
      <div
        style={{
          marginBlockEnd: token.marginXL,
          width: "2em",
          height: token.sizeXS,
          backgroundColor: token.colorPrimary,
        }}
      ></div>
    </Title>
  );
}

export default SectionTitle;
